var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-side-container" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        { staticClass: "inner-header login-side-flex" },
        [
          _c(
            "transition",
            {
              attrs: {
                "enter-active-class": "animated rotate",
                "leave-active-class": "animated rotate",
              },
            },
            [
              _c("q-img", {
                staticClass: "login-logo-img",
                attrs: { contain: "", ratio: 12 / 9, src: _vm.imgSrc },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "waves-container" }, [
        _c(
          "svg",
          {
            staticClass: "waves",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              "xmlns:xlink": "http://www.w3.org/1999/xlink",
              viewBox: "0 24 150 28",
              preserveAspectRatio: "none",
              "shape-rendering": "auto",
            },
          },
          [
            _c("defs", [
              _c("path", {
                attrs: {
                  id: "gentle-wave",
                  d: "M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z",
                },
              }),
            ]),
            _c("g", { staticClass: "parallax" }, [
              _c("use", {
                attrs: {
                  "xlink:href": "#gentle-wave",
                  x: "48",
                  y: "0",
                  fill: "rgba(10,85,151,0.7)",
                },
              }),
              _c("use", {
                attrs: {
                  "xlink:href": "#gentle-wave",
                  x: "48",
                  y: "1",
                  fill: "rgba(10,85,151,0.5)",
                },
              }),
              _c("use", {
                attrs: {
                  "xlink:href": "#gentle-wave",
                  x: "48",
                  y: "2",
                  fill: "rgba(10,85,151,0.3)",
                },
              }),
              _c("use", {
                attrs: {
                  "xlink:href": "#gentle-wave",
                  x: "48",
                  y: "3",
                  fill: "rgba(10,85,151,1)",
                },
              }),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "content flex" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }